/* .wrapper_table {
    overflow-x: scroll;
} */
.product_table {
	margin-top: 50px;
}
.detail_list {
	display: table;
	border-collapse: collapse;
	font-size: 14px;
	opacity: 1;
	transition: 0.3;
}
.detail_list:hover .row:first-child {
	opacity: 1;
}
.detail_list .row {
	display: table-row;
	transition: 0.1s;
}
.detail_list .row:hover {
	background-color: #f3f3f3;
}
.detail_list .row:first-child {
	font-weight: bold;
	background-color: #f3f3f3;
}
.detail_list .row .line {
	display: table-cell;
	border: 1px solid #ccc;
	padding: 5px;
}
.detail_list table {
	display: contents;
}
.detail_list tr {
	background-color: #f3f3f3;
}
.detail_list td {
	border: 1px solid #ccc;
	padding: 5px;
	text-align: center;
	vertical-align: middle;
	font-weight: bold;
}

.detail_list .row div input:not([type='radio']):not([type='checkbox']) {
	min-width: 60px;
	font-size: 14px;
}

.detail_list .row .welding {
	column-span: 7;
}

.detail_list .details_td td {
	background-color: initial;
	text-align: left;
	font-weight: normal;
}

.detail_list .details_td td p {
	font-size: 11px;
	margin: 0;
}

/* input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type='number'],
input[type='number']:hover,
input[type='number']:focus {
	appearance: none;
	-moz-appearance: textfield;
} */
