@font-face {
	font-family: 'Roboto';
	src: url('/public/fonts/roboto/Roboto-Regular.ttf');
}

@font-face {
	font-family: 'Roboto';
	src: url('/public/fonts/roboto/Roboto-Light.ttf');
	font-weight: 100;
}

@font-face {
	font-family: 'Roboto';
	src: url('/public/fonts/roboto/Roboto-Medium.ttf');
	font-weight: 500;
}

@font-face {
	font-family: 'Roboto';
	src: url('/public/fonts/roboto/Roboto-Bold.ttf');
	font-weight: 700;
}

@font-face {
	font-family: 'Roboto';
	src: url('/public/fonts/roboto/Roboto-Black.ttf');
	font-weight: 900;
}

body {
	margin: 0;
	font-family: 'Roboto', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
	margin: 80px 0 0;
}

#root .menu-button {
	position: fixed;
	height: 38px;
	right: 18px;
	top: 20px;
	z-index: 100;
}
#root .menu-button:hover .fi {
	color: #cc6666;
}
#root .menu-button .fi {
	display: flex;
	align-items: center;
	transition: 0.3s;
}

#root .back-link {
	text-decoration: none;
	width: max-content;
	margin-top: 0;
}
#root .doc-container .back-link {
	position: fixed;
	left: 18px;
	top: 20px;
}

#root .back-link::before {
	content: '\f08e';
	font-family: uicons-solid-rounded;
	font-size: 20px;
	position: relative;
	left: 0;
	top: 2px;
	padding-right: 5px;
	transition: 0.3s;
}

#root .back-link:hover::before {
	left: -10px;
	color: #cc6666;
}

#root .col {
	border: 1px solid black;
	box-sizing: border-box;
	padding: 20px;
}

.navbar {
	margin: 0 0 30px 0;
}

.modal .bg {
	width: 100%;
	height: 100%;
	background-color: #000;
	opacity: 0.5;
	position: fixed;
	left: 0;
	top: 0;
}

.fixed-element {
	position: fixed;
	right: 18px;
	top: 70px;
	display: flex;
	align-items: center;
	flex-direction: column;
}
.fixed-element .btn:not(:last-of-type) {
	margin-bottom: 12px;
}

.btn {
	min-height: 38px;
}
.btn .fi {
	display: flex;
	align-items: center;
	transition: 0.3s;
}
.btn:hover .fi {
	color: #cc6666;
}
.bottom-fixed-element {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 15px 0;
	background-color: #ffffff;
}

.main-page {
	padding-bottom: 50px;
}

.bottom-fixed-element .list-group-item {
	padding: 5px 10px;
}
.bottom-fixed-element .list-group-item:last-child {
	padding: 0 5px;
	font-size: 19px;
}

.alert-fixed {
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 100%;
	text-align: center;
	z-index: 100000;
}

.offcanvas .menu {
	margin: 0 0 0 0;
	padding: 0;
}
.offcanvas .menu li {
	list-style: none;
	line-height: 40px;
	text-align: center;
}
.offcanvas .menu li a {
	text-decoration: none;
	color: #343a40;
	font-size: 20px;
	position: relative;
}
.offcanvas .menu li a:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0;
	height: 1px;
	background-color: #343a40;
	transition: 0.3s;
}
.offcanvas .menu li a:hover:after {
	width: 100%;
}

.group-controll .list-group-item {
	padding: 0;
}
.group-controll .list-group-item a {
	padding: 5px 15px;
	display: block;
}
.group-controll .list-group-item button {
	padding: 5px 7px;
}
.group-controll .list-group-item button svg {
	width: 20px;
	height: 20px;
}

.custom-btn {
	border: none;
	background-color: initial;
}

/* General table with DIV element */
.table {
	display: table;
	border-collapse: collapse;
	width: auto !important;
}

.container input.form-control {
	padding: 0.375rem 0.375rem;
}

.table .row {
	display: table-row;
}

.table .header {
	font-weight: bold;
}

.table .row div {
	display: table-cell;
	border: 1px solid #ccc;
	padding: 5px;
	width: auto !important;
}

.list-group-item a {
	text-decoration: none;
	color: #343a40;
	font-weight: 700;
}

.list-group-item a:hover {
	color: initial;
}

.list-group-item {
	transition: 0.3s;
}

.list-group-item:hover {
	background-color: #0d6efd2d;
	cursor: pointer;
}

.group-controll {
	margin: 20px 0 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.controllers {
	padding: 10px;
	border: 1px solid #cbcbcb;
	box-sizing: border-box;
	width: max-content;
	display: flex;
	align-items: center;
}

.controllers form {
	display: flex;
	gap: 20px;
}

.controllers form .group {
	display: flex;
	gap: 10px;
	align-items: center;
}

.controllers form .group label {
	width: max-content;
	margin: 0;
	font-size: 14px;
}
.controllers form .group label img {
	max-height: 40px;
}
.controllers form .form-select {
	width: auto;
}

.controllers form button {
	margin: 0 0 0 10px;
}
.controllers form .markup {
	z-index: 2;
}
.controllers form .markup .pref__option {
	font-size: 14px;
}
.mr-1 {
	margin-right: 10px;
}
.multi_select_container {
	max-width: 75px;
}
.multi_select__control {
	cursor: pointer !important;
}
.multi_select__indicators {
	display: none !important;
}
.multi_select__value-container {
	padding: 2px !important;
}
.multi_select__multi-value__label {
	padding: 3px !important;
	font-size: 80% !important;
}
.multi_select__value-container input {
	height: 1px;
}
.multi_select__menu,
.multi_select__placeholder {
	font-size: 11px;
}

.width900 {
	max-width: 900px;
}
.width800 {
	max-width: 800px;
}
.width700 {
	max-width: 700px;
}
.width600 {
	max-width: 600px;
}
.custom_close {
	display: flex;
	align-items: center;
	justify-content: center;
}
.custom_close .btn-close {
	background-size: 10px;
}
.container-flued {
	margin-right: 2em;
}

.form-control.delivery {
	max-width: 80px;
}
.form-control.pallets {
	max-width: 60px;
}
.modal-dialog .form-label {
	font-weight: 500;
}

.borderBold {
	position: relative;
}
.borderBold::after {
	content: '';
	position: absolute;
	width: 100%;
	height: 2px;
	background-color: #dee2e6;
	top: 0;
	left: 0;
}
.scrollTop {
	background-color: #666666 !important;
	right: 18px !important;
	bottom: 40px !important;
	position: fixed !important;
	z-index: 2 !important;
	cursor: pointer !important;
	border-radius: 7px !important;
	width: 42px !important;
	height: 40px !important;
	border: none !important;
	color: white !important;
	transition: 0.3s;
}

.scrollTop:hover {
	color: #cc6666 !important;
}

.scrollTop .fi {
	display: flex;
	align-items: center;
	justify-content: center;
}

.polymer-select__value-container {
	padding: 2px 5px !important;
}
.polymer-select__value-container input {
	width: 0 !important;
	height: 0 !important;
	padding: 0 !important;
}
.polymer-select__control {
	min-height: 35px !important;
	border-radius: 0.375rem;
	background-color: initial !important;
	border-color: #ccc !important;
}
.polymer-select__option {
	padding: 5px !important;
	font-size: 11px !important;
}
.polymer-select__placeholder {
	font-size: 11px !important;
}
.polymer-select__dropdown-indicator,
.polymer-select__indicator-separator {
	display: none !important;
}
.polymer-select__indicator {
	padding: 3px !important;
}
.polymer-select__multi-value__label {
	padding-left: 3px !important;
}
.polymer-select__multi-value__remove {
	display: none !important;
}
.css-mohuvp-dummyInput-DummyInput {
	height: 0;
	padding: 0 !important;
}
.suffixes,
.chg_metals {
	font-size: 13px;
}
.narrow_cells > :not(caption) > * > * {
	padding: 0 0.25rem;
}
.narrow_cells > :not(caption) > * > th {
	padding: 0.25rem 0.25rem;
}
.client thead tr th:first-child {
	width: 40px;
}
.client tr th.full {
	width: 65%;
}
.filters-container {
	width: 100%;
}
.filters-select__control {
	border-radius: 0.375rem !important;
}

@media print {
	#root {
		margin: 0 !important;
		width: 100% !important;
	}
	table {
		border-color: #000 !important;
	}
	.borderBold::after {
		background-color: #000;
	}
	textarea.form-control {
		border-color: #000;
	}
	.container {
		width: 100% !important;
		margin: 0 !important;
		max-width: 100%;
	}
	.container div {
		width: 100% !important;
	}

	.menu-button,
	.back-link {
		display: none !important;
	}
	.page_brake {
		page-break-after: always;
	}

	@page {
		size: auto;
		/* auto is the initial value */
		margin: 0;
		padding: 0;
	}
}
