.setup {
	font-size: 14px;
	position: relative;
	padding: 0 0 20px;
	margin-bottom: 20px;
}
.setup::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: -15px;
	width: calc(100% + 30px);
	height: 2px;
	border-bottom: 2px dashed #0d6efd;
	opacity: 0.5;
}
.setup label {
	margin: 0 0 5px;
}

.flex .wrapp {
	display: flex;
	align-items: top;
	justify-content: space-between;
	gap: 10px;
}
.flex .group {
	width: 100%;
}
.details:not(:empty) {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	position: relative;
	padding: 0 0 20px;
}

/* .details:not(:empty)::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: -15px;
	width: calc(100% + 30px);
	height: 2px;
	border-bottom: 2px dashed #0d6efd;
	opacity: 0.5;
} */
.details .title {
	width: 100%;
	margin: 0;
	font-weight: bold;
	font-size: 18px;
}
.detail {
	width: calc(100% / 3 - 15px);
	font-size: 12px;
	margin-bottom: 20px;
}
.detail label {
	margin: 0;
}
.detail input {
	font-size: 12px;
	padding: 5px;
}
.detail button {
	font-size: 10px;
}
.detail_btn {
	font-size: 12px;
}
.detail_btn:not(:last-of-type) {
	margin-right: 10px;
}
.interface {
	margin-bottom: 20px;
	padding-bottom: 20px;
	position: relative;
}
.interface::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: -15px;
	width: calc(100% + 30px);
	height: 2px;
	border-bottom: 2px dashed #0d6efd;
	opacity: 0.5;
}
