/* .wrapper_table {
    overflow-x: scroll;
} */
.metal_table {
	margin-top: 50px;
}
.detail_list {
	display: table;
	border-collapse: collapse;
	font-size: 14px;
	opacity: 1;
	transition: 0.3;
	width: 100%;
}
.detail_list .theader {
	display: table-caption;
	border: 1px solid #ccc;
	border-bottom: none;
	padding: 5px;
	background-color: #f3f3f3;
	font-weight: bold;
	text-align: center;
	column-span: all;
}
.detail_list:hover .row:first-child {
	opacity: 1;
}
.detail_list .row {
	display: table-row;
	transition: 0.1s;
}
.detail_list .row.header {
	background-color: #f3f3f3;
	font-weight: bold;
}
.detail_list .row:hover {
	background-color: #f3f3f3;
}
.detail_list .row:first-child {
	font-weight: bold;
	background-color: #f3f3f3;
}
.detail_list .row div {
	display: table-cell;
	border: 1px solid #ccc;
	padding: 5px;
}

.detail_list .row div input:not([type='radio']):not([type='checkbox']) {
	min-width: 60px;
	font-size: 14px;
}

.updLists {
	width: 180px;
	text-align: center;
}
/* input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type='number'],
input[type='number']:hover,
input[type='number']:focus {
	appearance: none;
	-moz-appearance: textfield;
} */
