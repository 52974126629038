/* .wrapper_table {
    overflow-x: scroll;
} */

.detail_list {
	display: table;
	border-collapse: collapse;
	font-size: 14px;
	opacity: 1;
	transition: 0.3;
}
.detail_list.border_bold {
	border: 2px solid #8f8f8f;
}
.detail_list:hover .row:first-child {
	opacity: 1;
}
.detail_list .row {
	display: table-row;
	transition: 0.1s;
}
.detail_list .row.header {
	position: sticky;
	top: 0;
	z-index: 1;
}
.detail_list .row:hover {
	background-color: #f3f3f3;
}
.detail_list .row:first-child {
	font-weight: bold;
	background-color: #f3f3f3;
}
.detail_list .row.controll_panel {
	text-align: center;
	background-color: #f3f3f3;
}
.detail_list .row.controll_panel button {
	color: #333;
}

.detail_list .row .line {
	display: table-cell;
	border: 1px solid #ccc;
	padding: 5px;
}
.detail_list .row .line.radio {
	text-align: center;
}
.detail_list .row .line:nth-of-type(4),
.detail_list .row .line:nth-of-type(7),
.detail_list .row .line:nth-of-type(11),
.detail_list .row .line:nth-of-type(13),
.detail_list .row .line:nth-of-type(15),
.detail_list .row .line:nth-of-type(17),
.detail_list .row .line:nth-of-type(20) {
	border-left-width: 2px;
	border-left-color: #8f8f8f;
}
.detail_list .row .line input:not([type='radio']):not([type='checkbox']),
.detail_list .row .line select {
	background-color: initial;
}
.detail_list .row .line.brown {
	background-color: #7956491a;
}
.detail_list .row .line.green {
	background-color: #016a051a;
}
.detail_list .row .line.yellow {
	background-color: #b5b5001a;
}
.detail_list .row .line.orange {
	background-color: #ffa50026;
}
.detail_list .row .line.blue {
	background-color: #0000ff1a;
}
.detail_list .row .line.red {
	background-color: #ff00001a;
}
.detail_list .row .line.purple {
	background-color: #80007f1a;
}
.detail_list .row .line.colSpan_8 {
	grid-column: span 8;
}
.detail_list .row .line.name {
	width: 20%;
}
.detail_list .row.header {
	text-align: center;
}
.detail_list .row.header .small p {
	margin: 0;
	font-size: 10px;
	line-height: 10px;
}
.detail_list .row.header .small img {
	max-height: 40px;
	margin-bottom: 5px;
}
.detail_list .row.header .pp_options {
	min-width: 100px;
}
.table_total {
	display: contents;
}
.table_total tr {
	background-color: #f3f3f3;
}
.table_total td {
	border: 1px solid #ccc;
	padding: 5px;
	vertical-align: middle;
	font-weight: bold;
	border-top: 2px solid #8f8f8f;
}
.table_total td:nth-of-type(2),
.table_total td:nth-of-type(5),
.table_total td:nth-of-type(9),
.table_total td:nth-of-type(11),
.table_total td:nth-of-type(13),
.table_total td:nth-of-type(15),
.table_total td:nth-of-type(18),
.table_total td:nth-of-type(19) {
	border-left-width: 2px;
	border-left-color: #8f8f8f;
}
.table_total td:not(:first-child) {
	text-align: right;
}
.detail_list .row .line input:not([type='radio']):not([type='checkbox']) {
	min-width: 60px;
	max-width: 100px;
	font-size: 14px;
	padding: 0.375rem 0.375rem;
}
.detail_list .row select {
	padding: 0.375rem 1.25rem 0.375rem 0.35rem;
	background-position: right 0.2rem center;
	font-size: 14px;
	min-width: 70px;
}
.pp_hint p {
	margin: 0;
}
.pp_hint {
	text-align: left;
}
.pp_set_options {
	text-align: left;
}
/* input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type='number'],
input[type='number']:hover,
input[type='number']:focus {
	appearance: none;
	-moz-appearance: textfield;
} */
