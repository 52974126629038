h2,
h3 {
	margin-bottom: 20px;
}
.doc {
	display: flex;
	flex-direction: column;
	max-width: 900px;
	margin: 50px auto;
	box-sizing: border-box;
	padding: 30px;
	box-shadow: 0 0 30px -20px black;
}

.doc.full {
	max-width: 100%;
}

.doc table {
	font-size: 12px;
}

.doc table td {
	text-align: right;
}

.doc table thead tr th {
	text-align: center;
	vertical-align: middle;
}

.doc table .footer td {
	font-weight: 700;
}

.compony_inf {
	display: flex;
	justify-content: space-between;
	gap: 40px;
}

.compony_inf .logo img {
	max-width: 220px;
}

.order_inf .order_number {
	font-size: 1.2em;
	font-weight: 900;
	display: block;
	text-align: center;
	color: black;
	text-decoration: none;
	transition: 0.3s;
}

.order_inf .order_number:hover {
	color: #0d6efd;
}
.order_inf button {
	font-size: 12px;
	margin-bottom: 20px;
}
.mini_header {
	font-weight: bold;
	font-size: 18px;
}
.invoice_header {
	display: flex;
	margin: 50px 0 0;
	justify-content: space-between;
}

.signatures .top {
	margin-top: 30px;
}

.signatures .top p:first-of-type {
	text-align: center;
}

.signatures .top p span {
	display: inline-block;
	border-bottom: 1px solid black;
	min-width: 300px;
}

.signatures .blocks {
	display: flex;
	gap: 30px;
	justify-content: space-between;
}

.signatures .blocks p {
	width: 50%;
	display: flex;
}

.signatures .blocks p span {
	border-bottom: 1px solid black;
	width: 100%;
}

.signatures .right {
	margin: 0 0 0 auto;
	width: 60%;
	position: relative;
}

.signatures .right span {
	display: inline-block;
	background-color: #fff;
	z-index: 1;
	position: relative;
	padding: 0 5px 0 0;
	font-weight: 700;
}

.signatures .right:after {
	content: '';
	width: 100%;
	height: 1px;
	position: absolute;
	bottom: 5px;
	left: 0;
	background-color: #000;
}

.specifications {
	display: flex;
	justify-content: space-between;
	gap: 20px;
	margin-top: 40px;
}

.specifications .block {
	width: 50%;
}

.specifications .block .metal {
	width: 100% !important;
}

.specifications .block table thead tr:first-of-type th {
	text-align: center;
}

.specifications .block p {
	display: flex;
	width: 100%;
	position: relative;
}

.specifications .block p span {
	display: inline-block;
	background-color: #fff;
	z-index: 1;
	position: relative;
	padding: 0 5px 0 0;
}

.specifications .block p:not(:first-of-type):after {
	content: '';
	width: 100%;
	height: 1px;
	position: absolute;
	bottom: 5px;
	left: 0;
	background-color: #000;
}
.total {
	text-align: right;
}
.total p {
	margin: 0;
	font-size: 18px;
}
.hideTable {
	display: none;
}
.gap {
	margin-bottom: 20px;
}
.doc table td.center,
.doc table .footer td.center {
	text-align: center;
}
.doc table td.left {
	text-align: left;
}

.contract table {
	font-family: 'Times New Roman', Times;
}
.contract table thead tr td {
	text-align: center;
}
.comment {
	text-align: center;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
}
.comment .heading {
	width: 100%;
	margin: 0;
	font-weight: bold;
}
.comment .textarea {
	max-width: 500px;
	min-height: 100px;
	font-size: 14px;
}
.doc .customer {
	text-align: center;
	font-size: 20px;
}
.doc .flex {
	display: flex;
	justify-content: space-between;
}
.doc .flex .group {
	width: 50%;
}
.doc .flex .group .textarea_mini {
	font-size: 14px;
	margin-bottom: 15px;
}
.spec_name {
	width: 40%;
}
textarea.metal_comment {
	height: 20px;
	min-height: 20px;
	padding: 0 0.25rem !important;
	font-size: 12px !important;
}
.metal_comment_wrapper {
	width: 40%;
}

@media print {
	.doc,
	.doc.full {
		box-shadow: none;
		width: 100%;
		margin: 0;
		max-width: 1200px;
	}
	.order_inf button,
	.doc button {
		display: none;
	}
	.doc .flex .group .textarea_mini,
	.comment .textarea,
	.metal_comment {
		resize: none;
	}
	::placeholder {
		font-size: 0;
	}
	.metal_comment {
		border: none !important;
		padding: 0px 0.25rem !important;
	}
}
